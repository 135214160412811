import React from "react";
import styles from "./Ticker.module.scss";
import Marquee from "react-fast-marquee";

const Ticker = ({
  big,
  svg,
  speed,
  txt1,
  txt2,
  txt3,
  txt4,
  txt5,
  txt6,
  txt7,
}) => {
  return (
    <Marquee speed={speed}>
      <div className={`${big ? styles.bigTicker : styles.smallTicker}`}>
        {txt1 && (
          <>
            <span>{txt1}</span>
            <img src={svg} alt="" />
          </>
        )}
        {txt2 && (
          <>
            <span>{txt2}</span>
            <img src={svg} alt="" />
          </>
        )}
        {txt3 && (
          <>
            <span>{txt3}</span>
            <img src={svg} alt="" />
          </>
        )}
        {txt4 && (
          <>
            <span>{txt4}</span>
            <img src={svg} alt="" />
          </>
        )}
        {txt5 && (
          <>
            <span>{txt5}</span>
            <img src={svg} alt="" />
          </>
        )}
        {txt6 && (
          <>
            <span>{txt6}</span>
            <img src={svg} alt="" />
          </>
        )}
        {txt7 && (
          <>
            <span>{txt7}</span>
            <img src={svg} alt="" />
          </>
        )}
      </div>
    </Marquee>
  );
};

export default Ticker;
