import inpactCover from "../assets/work/cover/inpact.png";
import hungry from "../assets/work/cover/hungrymates.png";
import altabaixa from "../assets/work/cover/altabaixa.png";
//import feelsgood from "../assets/work/cover/feelsgood.png";
import together from "../assets/work/cover/together.png";
import weather from "../assets/work/cover/weatherapp.png";
//import tascoCover from "../assets/work/cover/tasco.png";

export const workCoverData = [
  {
    id: 0,
    imgSrc: inpactCover,
    alt: "um computador a mostrar uma aplicação de telereabilitação chamada INPACT",
    tag3Txt: "ux/ui design",
    tag3Id: "ux",
    tag2Txt: "web/app design",
    tag2Id: "web",
    tag1Txt: "front-end",
    tag1Id: "front",
    link: "/",
    year: 2023,
  },
  //---------------------------------------------------------------------------------
  //------------------------------------hungry mates---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 1,
    imgTop: true,
    imgSrc: hungry,
    alt: "",
    tag3Txt: "ux/ui design",
    tag3Id: "ux",
    tag2Txt: "web/app design",
    tag2Id: "web",
    tag1Txt: "front-end",
    tag1Id: "front",
    link: "/",
    year: 2023,
  },
  //---------------------------------------------------------------------------------
  //------------------------------------alta & baixa---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 2,
    imgSrc: altabaixa,
    alt: "",
    tag3Txt: "web/app design",
    tag3Id: "web",
    tag2Txt: "front-end",
    tag2Id: "front",
    tag1Txt: "graphic design",
    tag1Id: "graphic",
    link: "/",
    year: 2021,
  },
  //---------------------------------------------------------------------------------
  //------------------------------------together apart---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 3,
    imgSrc: together,
    alt: "imagem de um computador a mostrar uma aplicação de telereabilitação chamada INPACT",
    tag3Txt: "ux/ui design",
    tag3Id: "ux",
    tag2Txt: "web/app design",
    tag2Id: "web",
    tag1Txt: "graphic design",
    tag1Id: "graphic",
    link: "/",
    year: 2020,
  },
  //---------------------------------------------------------------------------------
  //------------------------------------weather app---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 4,
    imgSrc: weather,
    alt: "dois telemóveis a mostrar uma aplicação de meteorologia. o da esquerda tem excrito 'Up to Weather', que é o nome da aplicação e o segundo tem um ecrã da meteorologia para coimbra. O fundo da imagem tem um gradiente",
    tag3Txt: "ux/ui design",
    tag3Id: "ux",
    tag2Txt: "web/app design",
    tag2Id: "web",
    tag1Txt: "graphic design",
    tag1Id: "graphic",
    link: "/",
    year: 2023,
  },
  // //---------------------------------------------------------------------------------
  // //------------------------------------feels good---------------------------------
  // //---------------------------------------------------------------------------------
  // {
  //   id: 3,
  //   imgSrc: feelsgood,
  //   alt: "imagem de um computador a mostrar uma aplicação de telereabilitação chamada INPACT",
  //   tag3Txt: "ux/ui",
  //   tag3Id: "ux",
  //   tag2Txt: "web/app design",
  //   tag2Id: "web",
  //   tag1Txt: "front-end",
  //   tag1Id: "front",
  //   link: "/",
  //   year: 2020,
  // },
  // //---------------------------------------------------------------------------------
  // //------------------------------------tasco do galo---------------------------------
  // //---------------------------------------------------------------------------------
  // {
  //   id: 5,
  //   imgSrc: tascoCover,
  //   alt: "uma página web com uma imagem de um restaurante e um desenho de duas pessoas a brindar",
  //   tag2Txt: "web/app design",
  //   tag2Id: "web",
  //   tag1Txt: "front-end",
  //   tag1Id: "front",
  //   link: "/",
  //   year: 2023,
  // },
];
