import React from "react";
import Tags from "../Tags";
import styles from "./WorkCards.module.scss";
import { useNavigate } from "react-router-dom";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";

const WorkCards = ({
  imgSrc,
  alt,
  tag1Txt,
  tag1Id,
  tag2Txt,
  tag2Id,
  tag3Txt,
  tag3Id,
  href,
  id,
  favoriteStates,
  toggleFavorite,
  imgTop,
  home = false,
}) => {
  const navigate = useNavigate();

  const handleHeartClick = () => {
    toggleFavorite({ id });
  };

  const handleCardClick = () => {
    navigate(`/work/${id}`);
  };

  console.log(id, favoriteStates);

  return (
    <div
      className={`${styles.workCardBox} ${home ? styles.cardHome : ""} ${
        imgTop ? styles.imgTop : ""
      }  `}
    >
      {home ? null : (
        <div>
          {favoriteStates ? (
            <AiFillHeart
              className={`${styles.iconHeart} ${styles.heartActive}`}
              onClick={handleHeartClick}
            />
          ) : (
            <AiOutlineHeart
              className={styles.iconHeart}
              onClick={handleHeartClick}
            />
          )}
        </div>
      )}
      <a href={href} onClick={handleCardClick}>
        <img src={imgSrc} alt={alt} />
      </a>
      <div className={styles.workTags}>
        <Tags text={tag1Txt} id={tag1Id} />
        <Tags text={tag2Txt} id={tag2Id} />
        <Tags text={tag3Txt} id={tag3Id} />
      </div>
    </div>
  );
};

export default WorkCards;
