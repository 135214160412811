import React, { useEffect } from "react";
import styles from "./RotatingElement.module.scss";

const RotatingElement = () => {
  useEffect(() => {
    const elements = document.querySelectorAll(`.${styles.svgContainer} svg`);
    const initialXPositions = [0, 50, 100];
    const rotationSpeeds = [1, 2, 3];

    const handleScroll = () => {
      const scrollX = window.scrollY;

      elements.forEach((element, index) => {
        const xPosition = initialXPositions[index] + scrollX;
        const rotation = (scrollX * rotationSpeeds[index]) / 3;

        element.style.transform = `translateX(${xPosition}px) rotate(${rotation}deg)`;
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={styles.rotatingElementContainer}>
      {/*SVG*/}
      <div className={styles.svgContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="97"
          height="101"
          viewBox="0 0 97 101"
          fill="none"
        >
          <path
            d="M66.9343 49.5629L66.1412 50.0308L66.9655 50.4412L93.5356 63.6709L63.9889 60.8439L63.0723 60.7562L63.4979 61.5727L77.2174 87.8933L54.9753 68.2391L54.2853 67.6294L54.1497 68.5401L49.7781 97.8981L43.3363 68.9239L43.1365 68.0251L42.4914 68.6822L21.6986 89.8637L33.5177 62.6367L33.8844 61.7921L32.9763 61.9445L3.70433 66.859L29.2698 51.779L30.0629 51.3112L29.2386 50.9008L2.66846 37.671L32.2151 40.4981L33.1317 40.5858L32.7061 39.7692L18.9867 13.4486L41.2287 33.1028L41.9187 33.7126L42.0544 32.8018L46.4259 3.44388L52.8677 32.418L53.0676 33.3169L53.7126 32.6598L74.5054 11.4783L62.6864 38.7052L62.3197 39.5499L63.2278 39.3974L92.4997 34.4829L66.9343 49.5629Z"
            stroke="#FA07BC"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="97"
          height="97"
          viewBox="0 0 97 97"
          fill="none"
        >
          <path
            d="M34.0289 77.0234C23.8995 81.7787 15.7161 82.8197 12.4362 78.9681C9.15644 75.1165 11.488 67.2035 17.7965 57.9612M79.4088 38.3802C85.7173 29.1379 88.0489 21.2249 84.7691 17.3733C81.4893 13.5217 73.3058 14.5627 63.1765 19.318M58.5678 79.0958C67.735 85.3146 75.5728 87.5963 79.4001 84.3371C83.2274 81.078 82.2236 72.9769 77.5449 62.9359M38.6375 17.2457C29.4702 11.0269 21.6325 8.74516 17.8052 12.0043C13.9515 15.2859 14.9957 23.4765 19.7577 33.6135M33.7945 77.1498C35.8825 87.9927 39.8055 95.1204 44.8064 95.5214C49.8385 95.9248 54.8785 89.4301 58.6758 78.9437M38.5456 17.3546C42.3411 6.89352 47.3741 0.417253 52.3993 0.820164C57.4001 1.22112 61.3231 8.34885 63.4112 19.1918M77.5819 62.9789C88.4247 60.8909 95.5524 56.9679 95.9533 51.9671C96.3543 46.9662 89.9427 41.9577 79.5709 38.1687M17.6345 58.1725C7.2628 54.3836 0.851183 49.375 1.25214 44.3742C1.65505 39.3489 8.85049 35.4121 19.7826 33.3319"
            stroke="#FA07BC"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="74"
          height="74"
          viewBox="0 0 74 74"
          fill="none"
        >
          <path
            d="M72.3131 3.74723L52.5638 36.9487C52.4762 37.0959 52.4701 37.2777 52.5476 37.4305L70.0261 71.8815L36.8247 52.1322C36.6775 52.0446 36.4957 52.0385 36.3429 52.116L1.89187 69.5945L21.6412 36.3931C21.7287 36.2459 21.7349 36.064 21.6573 35.9113L4.17884 1.46025L37.3803 21.2096C37.5275 21.2971 37.7093 21.3032 37.8621 21.2257L72.3131 3.74723Z"
            stroke="#FA07BC"
            stroke-linejoin="round"
          />
        </svg>
      </div>

      {/*SVG STOPPED*/}
      <div className={styles.svgStopped}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="97"
          height="101"
          viewBox="0 0 97 101"
          fill="none"
        >
          <path
            d="M66.9343 49.5629L66.1412 50.0308L66.9655 50.4412L93.5356 63.6709L63.9889 60.8439L63.0723 60.7562L63.4979 61.5727L77.2174 87.8933L54.9753 68.2391L54.2853 67.6294L54.1497 68.5401L49.7781 97.8981L43.3363 68.9239L43.1365 68.0251L42.4914 68.6822L21.6986 89.8637L33.5177 62.6367L33.8844 61.7921L32.9763 61.9445L3.70433 66.859L29.2698 51.779L30.0629 51.3112L29.2386 50.9008L2.66846 37.671L32.2151 40.4981L33.1317 40.5858L32.7061 39.7692L18.9867 13.4486L41.2287 33.1028L41.9187 33.7126L42.0544 32.8018L46.4259 3.44388L52.8677 32.418L53.0676 33.3169L53.7126 32.6598L74.5054 11.4783L62.6864 38.7052L62.3197 39.5499L63.2278 39.3974L92.4997 34.4829L66.9343 49.5629Z"
            stroke="#FA07BC"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="97"
          height="97"
          viewBox="0 0 97 97"
          fill="none"
        >
          <path
            d="M34.0289 77.0234C23.8995 81.7787 15.7161 82.8197 12.4362 78.9681C9.15644 75.1165 11.488 67.2035 17.7965 57.9612M79.4088 38.3802C85.7173 29.1379 88.0489 21.2249 84.7691 17.3733C81.4893 13.5217 73.3058 14.5627 63.1765 19.318M58.5678 79.0958C67.735 85.3146 75.5728 87.5963 79.4001 84.3371C83.2274 81.078 82.2236 72.9769 77.5449 62.9359M38.6375 17.2457C29.4702 11.0269 21.6325 8.74516 17.8052 12.0043C13.9515 15.2859 14.9957 23.4765 19.7577 33.6135M33.7945 77.1498C35.8825 87.9927 39.8055 95.1204 44.8064 95.5214C49.8385 95.9248 54.8785 89.4301 58.6758 78.9437M38.5456 17.3546C42.3411 6.89352 47.3741 0.417253 52.3993 0.820164C57.4001 1.22112 61.3231 8.34885 63.4112 19.1918M77.5819 62.9789C88.4247 60.8909 95.5524 56.9679 95.9533 51.9671C96.3543 46.9662 89.9427 41.9577 79.5709 38.1687M17.6345 58.1725C7.2628 54.3836 0.851183 49.375 1.25214 44.3742C1.65505 39.3489 8.85049 35.4121 19.7826 33.3319"
            stroke="#FA07BC"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="74"
          height="74"
          viewBox="0 0 74 74"
          fill="none"
        >
          <path
            d="M72.3131 3.74723L52.5638 36.9487C52.4762 37.0959 52.4701 37.2777 52.5476 37.4305L70.0261 71.8815L36.8247 52.1322C36.6775 52.0446 36.4957 52.0385 36.3429 52.116L1.89187 69.5945L21.6412 36.3931C21.7287 36.2459 21.7349 36.064 21.6573 35.9113L4.17884 1.46025L37.3803 21.2096C37.5275 21.2971 37.7093 21.3032 37.8621 21.2257L72.3131 3.74723Z"
            stroke="#FA07BC"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default RotatingElement;
