import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Tags from "../../General/Tags";
import { workHeroData } from "../../../Data/workHeroData";
import styles from "./ProjectHero.module.scss";

const ProjectsHero = () => {
  const { id } = useParams();
  const [imageFull1, setImageFull1] = useState(false);
  const [imageFull2, setImageFull2] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [cardStates, setCardStates] = useState(workHeroData[id]);
  const handleImageClick1 = () => {
    setImageFull1(!imageFull1);
  };

  const handleImageClick2 = () => {
    setImageFull2(!imageFull2);
  };
  const bodyStyle = imageFull1 || imageFull2 ? { overflow: "hidden" } : {};

  useEffect(() => {
    document.body.style.overflow = bodyStyle.overflow || "auto";
  }, [bodyStyle.overflow]);

  return (
    <section className={styles.projHeroSection}>
      <div className={styles.projTxtContent}>
        <div>
          <h1>{cardStates.title}</h1>
          <div className={styles.tags}>
            <Tags text={cardStates.tag1Txt} id={cardStates.tag1Id} />
            <Tags text={cardStates.tag2Txt} id={cardStates.tag2Id} />
            <Tags text={cardStates.tag3Txt} id={cardStates.tag3Id} />
          </div>
        </div>
        <p dangerouslySetInnerHTML={{ __html: cardStates.para }} />
        <div className={styles.keyTools}>
          <div>
            <span className={styles.bold}>Keywords:</span>{" "}
            <span>{cardStates.keywords}</span>
          </div>
          <div>
            <span className={styles.bold}>Tools:</span>{" "}
            <span>{cardStates.tools}</span>
          </div>
        </div>
      </div>
      <div className={styles.imgContainer}>
        <div
          className={styles.imgBox}
          id={styles.imgBox1}
          onClick={handleImageClick1}
        >
          <span className={styles.biggerImg}>Click me to see me bigger</span>
          {imageFull1 && (
            <div className={styles.imgFull} onClick={handleImageClick1}>
              <div className={styles.imgFullContent}>
                <figure>
                  <img src={cardStates.imgSrc1} alt={cardStates.imgAlt1} />
                </figure>
              </div>
            </div>
          )}
          <img src={cardStates.imgSrc1} alt={cardStates.imgAlt1} />
        </div>
        <div
          className={styles.imgBox}
          id={styles.imgBox2}
          onClick={handleImageClick2}
        >
          <span className={styles.biggerImg}>Click me to see me bigger</span>
          {imageFull2 && (
            <div className={styles.imgFull} onClick={handleImageClick2}>
              <div className={styles.imgFullContent}>
                <figure>
                  <img src={cardStates.imgSrc2} alt={cardStates.imgAlt2} />
                </figure>
              </div>
            </div>
          )}
          <img src={cardStates.imgSrc2} alt={cardStates.imgAlt2} />
        </div>
      </div>
    </section>
  );
};

export default ProjectsHero;
