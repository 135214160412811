// id:0
import inpact1 from "../assets/work/inpact/1.png";
import inpact2 from "../assets/work/inpact/2.png";

// id:1
import hungry1 from "../assets/work/hungrymates/hero1.png";
import hungry2 from "../assets/work/hungrymates/hero2.png";

// id:2
import alta1 from "../assets/work/altabaixa/1.png";
import alta2 from "../assets/work/altabaixa/2.png";

// id:3 - together apart
import together1 from "../assets/work/together/cover1.png";
import together2 from "../assets/work/together/cover2.png";

// id:4 - weather app
import weather1 from "../assets/work/weather/hero1.png";
import weather2 from "../assets/work/weather/hero2.png";

// id:- - feels good

// id:- - tasco galo

export const workHeroData = [
  {
    id: 0,
    title: "INPACT",
    imgSrc1: inpact1,
    imgAlt1: "",
    imgSrc2: inpact2,
    imgAlt2: "",
    para: "<b>INPACT</b> (”Intelligent Platform for Autonomous Collaborative Telerehabilitation”) is an innovation project funded by the Portugal2020 program and European Union’s structural funds.<br/> <br/> The goal of the project was to develop an innovative solution for monitoring physiotherapy sessions at home. This tracks the user's performance and provides real-time session feedback via an appealing and gamified interface that employs playful activities. <br/> <br/>This project followed a Human-Centred Design approach and was carried through my dissertation.",
    keywords:
      "UX/UI Design, Gamification, Product Design, Prototype, Rehabilitation, Telerehabilitation, Front-End, Human-Centred Design.",
    tools: "Figma, Illustrator, React JS, CSS.",
    tag1Txt: "ux/ui design",
    tag1Id: "ux",
    tag2Txt: "web/app design",
    tag2Id: "web",
    tag3Txt: "front-end",
    tag3Id: "front",
  },
  //---------------------------------------------------------------------------------
  //------------------------------------Hungry Mates---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 1,
    title: "Hungry Mates",
    imgSrc1: hungry1,
    imgAlt1: "",
    imgSrc2: hungry2,
    imgAlt2: "",
    para: "Attempting to schedule meetings with friends but having no luck? Your rescue is at hand thanks to <b>Hungry Mates</b>. This website enables friends and family to organise events devoid of spam and member uncertainties. <br/> <br/> Hungry Mates allows you to create event plans and let your friends vote on them, whether for the type of event, location, date and availability through polls. <br/> <br/> This project was completed during RedLight Summer Internship '23 and was a collaboration with more five interns, four of them developers and another designer. My position was as UX/UI designer and front-end developer",
    keywords: "UX/UI, Product Design, Prototype, Event Planning, Front-end.",
    tools: "Figma, React JS, Typescript, Sass.",
  },
  //---------------------------------------------------------------------------------
  //------------------------------------alta & baixa---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 2,
    title: "Alta e Baixa",
    imgSrc1: alta1,
    imgAlt1: "",
    imgSrc2: alta2,
    imgAlt2: "",
    para: "Alta & Baixa is a Coimbra-based typographic magazine that focuses on the history and various aspects of typography. <br/> <br/> Its graphic identity incorporates vernacular typography from Coimbra's historic buildings and streets. Furthermore, its main colour is orange, which is the predominant colour in Coimbra's uptown and down town areas. <br/> <br/> This project was part of the first-year curriculum for the Master's in Design and Multimedia course at FCTUC. Under the guidance of Professor Artur Rebelo, and was a collaboration with Angela Laspriella, Carolina Almeida and Rita Estevinhas.",
    keywords:
      "Magazine, Branding, Typography, Editorial Design, Graphic Design, Website",
    tools: "Indesign, Figma, ReactJS",
    tag1Txt: "web/app design",
    tag1Id: "web",
    tag2Txt: "front-end",
    tag2Id: "front",
    tag3Txt: "graphic design",
    tag3Id: "graphic",
  },
  // //---------------------------------------------------------------------------------
  // //------------------------------------feels good---------------------------------
  // //---------------------------------------------------------------------------------
  // {
  //   id: 3,
  //   title: "Feels Good",
  //   para: "Feels Good is a mobile app whose ultimate goal is to improve the well-being of people who are unmotivated.<br/><br/>Following research and interviews with a psychiatrist, it was determined that performing small tasks promotes feelings of joy, motivation, and reward, resulting in people being more satisfied, happy, and willing to be more productive. As a result, it was decided that the application should focus on completing these minor tasks. Feels Good suggests activities that are suitable for each user so that they can participate in them when they are feeling down. In addition, the application can also be used as an agenda, as each user can add an activity that is not suggested by the app.<br/> <br/> This project was completed in the third year of the Design and Multimedia course at FCTUC, for the curricular unit Interactive Media Design. Professor Jorge Cardoso oversaw this academic project, which was created in collaboration with Angela Laspriella and Mariana Ferreira.",
  //   keywords: "Mobile Application, UX/UI Design, Branding, Mental Health",
  //   tools: "Illustrator, Figma, Miro",
  // },
  //---------------------------------------------------------------------------------
  //------------------------------------Together Apart---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 3,
    title: "Together Apart",
    imgSrc1: together1,
    imgAlt1: "",
    imgSrc2: together2,
    imgAlt2: "",
    para: "We were tasked with identifying a problem in the field of health services and proposing innovative solutions using a service design approach, all with the aim of improving people's lives. <br/> <br/>To select a problem to address, we interviewed individuals with first-hand experience in healthcare. This helped us gain a deeper understanding of the existing issues. <br/> <br/>This project was part of the first-year curriculum for the Master's in Design and Multimedia course at FCTUC. Under the guidance of Professor Paula Alexandra, and was a collaboration with Isa Roxo, Rafaela Costa, and Teresa Martins to tackle the challenges of service design in healthcare.",
    keywords:
      "Service Design, Mobile Application, UX/UI Design, Product Design, Health Care",
    tools: "Figma, Miro, Illustrator",
  },
  //---------------------------------------------------------------------------------
  //------------------------------------Weather App---------------------------------
  //---------------------------------------------------------------------------------
  {
    id: 4,
    title: "Up to Weather",
    imgSrc1: weather2,
    imgAlt1: "",
    imgSrc2: weather1,
    imgAlt2: "",
    para: "So many weather apps and all look so alike, am I right? Well… wait no more, Up To Weather is your new must-have weather app. <br/> <br/> You may wonder what sets this app apart from others 🤔. This app provides you with unwanted advice (who doesn’t love it 😉) and activities based on the weather! Are you going to get annoyed, sometimes? Oh yes! This app is no fake friend and she knows everything 💀. <br/><br/> This is just a prototype and was done to challenge myself to create more user interfaces for my portfolio, I did a ‘45 UI Challenge’ as I called it 🤠. If you continue reading, you will learn more about this!",
    keywords: "UI Design, Product Design, Prototype, Weather App",
    tools: "Figma",
  },
  // //---------------------------------------------------------------------------------
  // //------------------------------------Tasco Galo---------------------------------
  // //---------------------------------------------------------------------------------
  // {
  //   id: 5,
  //   title: "Tasco do Galo",
  //   para: "Tasco do Galo is a fictional tasco located at the heart of Coimbra. <br/><br/> The goal of this project was to design and implement a responsive website for a tavern. Roosters (“galo”) are notorious for being loud and constantly crowing, just like taverns. As a result, “Tasco do Galo” was born.<br/><br/> This project was designed for the selection period of Redlight Summer Intership '23.",
  //   keywords: "Website, UX/UI Design, Branding, Front-End",
  //   tools: "Figma, Procreate, HTML, CSS, Javascript, Lottie Animations",
  // },
];
