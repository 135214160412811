import React from "react";
import styles from "./Footer.module.scss";
import instagram from "../../../assets/socials/instagram.png";
import linkedin from "../../../assets/socials/linkedin.png";
import behance from "../../../assets/socials/behance.png";

const Footer = () => {
  return (
    <footer>
      <div className={styles.line}></div>
      <div className={styles.footerContent}>
        <div className={styles.socialLinks}>
          <a
            href="https://www.instagram.com/francisca_laureano_/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instagram} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/in/francisca-laureano-0a5023207/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="" />
          </a>
          <a
            href="https://www.behance.net/franciscalaureano"
            target="_blank"
            rel="noreferrer"
          >
            <img src={behance} alt="" />
          </a>
        </div>
        <span>Designed by Francisca Laureano (aka me👩🏻‍💻)</span>
      </div>
    </footer>
  );
};

export default Footer;
