import { useEffect, useState } from "react";
import styles from "./Navbar.module.scss";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  const [navBurguerOpen, setNavBurguerOpen] = useState(false);

  const handleNavBurguer = () => {
    setNavBurguerOpen(!navBurguerOpen);
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {}, [location]);

  return (
    <section className={styles.navSection}>
      <nav>
        <span>
          <a href="/" onClick={handleClick}>
            laureano
          </a>
        </span>
        <ul className={styles.navPcVersion}>
          <li>
            <Link
              className={location.pathname === "/" ? styles.isActive : ""}
              to="/"
              onClick={handleClick}
            >
              home
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname === "/work" ? styles.isActive : ""}
              to="/work"
              onClick={handleClick}
            >
              work
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname === "/about" ? styles.isActive : ""}
              to="/about"
              onClick={handleClick}
            >
              about
            </Link>
          </li>
          <li>
            <Link
              className={
                location.pathname === "/contact" ? styles.isActive : ""
              }
              to="/contact"
              onClick={handleClick}
            >
              contact
            </Link>
          </li>
        </ul>

        <div
          className={`${styles.navBurguer} ${navBurguerOpen && styles.open}`}
          onClick={handleNavBurguer}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="39"
            viewBox="0 0 39 39"
            fill="none"
          >
            <path
              d="M19 1V38"
              stroke="#EAE8E2"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M1 19L38 19"
              stroke="#EAE8E2"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
        {navBurguerOpen && (
          <div className={styles.navBurguerContent}>
            <ul>
              <li onClick={handleNavBurguer}>
                <Link
                  className={location.pathname === "/" ? styles.isActive : ""}
                  to="/"
                  onClick={handleClick}
                >
                  home
                </Link>
              </li>
              <li onClick={handleNavBurguer}>
                <Link
                  className={
                    location.pathname === "/work" ? styles.isActive : ""
                  }
                  to="/work"
                  onClick={handleClick}
                >
                  work
                </Link>
              </li>
              <li onClick={handleNavBurguer}>
                <Link
                  className={
                    location.pathname === "/about" ? styles.isActive : ""
                  }
                  to="/about"
                  onClick={handleClick}
                >
                  about
                </Link>
              </li>
              <li>
                <Link
                  className={
                    location.pathname === "/contact" ? styles.isActive : ""
                  }
                  to="/contact"
                  onClick={handleClick}
                >
                  contact
                </Link>
              </li>
            </ul>
          </div>
        )}
      </nav>
      <div className={styles.line}></div>
    </section>
  );
};

export default Navbar;
