import React from "react";
import TimeSection from "../TimeSection";
import styles from "./TimeLine.module.scss";

const TimeLine = () => {
  return (
    <section className={styles.timeLineSection}>
      {/*---------FIRST---------*/}
      <div className={styles.timeContainer}>
        <div className={styles.timeIdentifier}>
          <span>Education</span>
          <div className={styles.line} />
        </div>
        <div className={styles.timeSectionContainer}>
          <div id={styles.firstEd}>
            <TimeSection
              text="Bachelor's Degree in Design and Multimedia"
              time="three"
              location="left"
              id="bachelor"
            />
          </div>
          <div id={styles.secondEd}>
            <TimeSection
              text="Master's Degree in Design and Multimedia"
              time="two"
              location="right"
              style={{ gridColumn: "1/2", gridRow: "2" }}
            />
          </div>
        </div>
      </div>
      {/*---------SECOND---------*/}
      <div className={styles.timeContainer}>
        <div className={styles.timeIdentifier}>
          <span>Summer Intership</span>
          <div className={styles.line} />
        </div>
        <div className={`${styles.timeSectionContainer} ${styles.timeMiddle}`}>
          <div id={styles.firstInter}>
            <TimeSection text="RedLight Software" />
          </div>
        </div>
      </div>

      {/*---------THIRD---------*/}
      <div className={styles.timeContainer}>
        <div className={styles.timeIdentifier}>
          <span>jeKnowledge</span>
          <div className={styles.line} />
        </div>
        <div className={`${styles.timeSectionContainer} ${styles.timeMiddle}`}>
          <div id={styles.firstjeK}>
            <TimeSection text="Junior Designer" />
          </div>
          <div id={styles.secondjeK}>
            <TimeSection text="Senior Designer" />
          </div>
          <div id={styles.thirdjeK}>
            <TimeSection text="Technology Department Coordinator" />
          </div>
        </div>
      </div>

      {/*---------FOURTH---------*/}
      <div className={styles.timeContainer}>
        <div className={styles.timeIdentifier}>
          <span>Shift APPens</span>
          <div className={styles.line} />
        </div>
        <div className={`${styles.timeSectionContainer} ${styles.timeBottom}`}>
          <div id={styles.firstShift}>
            <TimeSection text="Design Collaborator" />
          </div>
          <div id={styles.secondShift}>
            <TimeSection text="General Coordinator" />
          </div>
        </div>
      </div>
      <div className={styles.timeDatesContainer}>
        <div className={styles.timeDates}>
          <span>2018</span>
          <span>2019</span>
          <span>2020</span>
          <span>2021</span>
          <span>2022</span>
          <span>2023</span>
        </div>
      </div>
      <a
        href="https://drive.google.com/file/d/1jd04YbcQZjFXCWPhWuq0kfz-ld_uDXLJ/view?usp=drive_link"
        target="_blank"
        className={styles.cvNote}
        rel="noreferrer"
      >
        For a more detailed background experience please refer to my cv
      </a>
    </section>
  );
};

export default TimeLine;
