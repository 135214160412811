import React from "react";
import styles from "./Tags.module.scss";

const Tags = ({ text, id }) => {
  const tagClass = `${styles.tag} ${styles[id] || ""}`;

  return <div className={tagClass}>{text}</div>;
};

export default Tags;
