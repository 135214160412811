import React from "react";
import WorkCards from "../../General/WorkCards";
import styles from "./WorkGrid.module.scss";

const WorkGrid = ({ projects, favoriteStates, toggleFavorite }) => {
  return (
    <section className={styles.workGridContainer}>
      {projects.map((val, key) => {
        return (
          <>
            <WorkCards
              id={val.id}
              imgTop={val.imgTop}
              imgSrc={val.imgSrc}
              alt={val.alt}
              tag1Txt={val.tag1Txt}
              tag1Id={val.tag1Id}
              tag2Txt={val.tag2Txt}
              tag2Id={val.tag2Id}
              tag3Txt={val.tag3Txt}
              tag3Id={val.tag3Id}
              tag4Txt={val.tag4Txt}
              tag4Id={val.tag4Id}
              tag5Txt={val.tag5Txt}
              tag5Id={val.tag5Id}
              favoriteStates={favoriteStates[val.id]}
              toggleFavorite={() => toggleFavorite(val.id)}
            />
          </>
        );
      })}
    </section>
  );
};

export default WorkGrid;
