import styles from "./ProjectContent.module.scss";
import ProjectImgGrid from "../ProjectImgGrid";

const ProjectContent = ({
  topic,
  subTopic1,
  subTopic2,
  subTopic3,
  subTopic4,
  subTopic5,
  p,
  p1,
  p2,
  p3,
  p4,
  p5,
  img,
  img0,
  img1,
  img11,
  img2,
  img21,
  img3,
  img31,
  img4,
  img41,
  img5,
  img51,
  alt,
  alt0,
  alt1,
  alt11,
  alt2,
  alt21,
  alt3,
  alt31,
  alt4,
  alt41,
  alt5,
  alt51,
  idTopic,
  idSubTopic1,
  idSubTopic2,
  idSubTopic3,
  idSubTopic4,
  idSubTopic5,
}) => {
  return (
    <>
      {topic && (
        <div className={styles.projectContentContainer} id={idTopic}>
          {/*--------MAIN-------- */}
          <h2>{topic}</h2>
          {p && <p dangerouslySetInnerHTML={{ __html: p }} />}
          {/*--------IMG-------- */}
          {img && (
            <ProjectImgGrid img1={img} alt1={alt} img2={img0} alt2={alt0} />
          )}
          {/*SUB1 */}
          {subTopic1 && (
            <div id={idSubTopic1} className={styles.subTopics}>
              <h3>{subTopic1}</h3>
              {p1 && <p dangerouslySetInnerHTML={{ __html: p1 }} />}
              {img1 && (
                <ProjectImgGrid
                  img1={img1}
                  alt1={alt1}
                  img2={img11}
                  alt2={alt11}
                />
              )}
            </div>
          )}
          {/*SUB2 */}
          {subTopic2 && (
            <div id={idSubTopic2} className={styles.subTopics}>
              <h3>{subTopic2}</h3>
              {p2 && <p dangerouslySetInnerHTML={{ __html: p2 }} />}
              {img2 && (
                <ProjectImgGrid
                  img1={img2}
                  alt1={alt2}
                  img2={img21}
                  alt2={alt21}
                />
              )}
            </div>
          )}
          {/*SUB3 */}
          {subTopic3 && (
            <div id={idSubTopic3} className={styles.subTopics}>
              <h3>{subTopic3}</h3>
              {p3 && <p dangerouslySetInnerHTML={{ __html: p3 }} />}
              {img3 && (
                <ProjectImgGrid
                  img1={img3}
                  alt1={alt3}
                  img2={img31}
                  alt2={alt31}
                />
              )}
            </div>
          )}
          {/*SUB4 */}
          {subTopic4 && (
            <div id={idSubTopic4} className={styles.subTopics}>
              <h3>{subTopic4}</h3>
              {p4 && <p dangerouslySetInnerHTML={{ __html: p4 }} />}
              {img4 && (
                <ProjectImgGrid
                  img1={img4}
                  alt1={alt4}
                  img2={img41}
                  alt2={alt41}
                />
              )}
            </div>
          )}
          {/*SUB5 */}
          {subTopic5 && (
            <div id={idSubTopic5} className={styles.subTopics}>
              <h3>{subTopic5}</h3>
              {p5 && <p dangerouslySetInnerHTML={{ __html: p5 }} />}
              {img5 && (
                <ProjectImgGrid
                  img1={img5}
                  alt1={alt5}
                  img2={img51}
                  alt2={alt51}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProjectContent;
