import React from "react";
import styles from "./Button.module.scss";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";

const Button = ({ text, active, anotherButton, onClick, arrow }) => {
  return text ? (
    <button
      className={`${styles.button} ${
        anotherButton ? styles.anotherButton : ""
      } ${active ? styles.activeButton : ""}`}
      onClick={onClick}
    >
      {arrow ? (
        <div className={styles.buttonArrow}>
          {text}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="9"
            viewBox="0 0 16 9"
            fill="none"
          >
            <path
              d="M7.29289 8.20711C7.68342 8.59763 8.31658 8.59763 8.70711 8.20711L15.0711 1.84315C15.4616 1.45262 15.4616 0.819456 15.0711 0.428932C14.6805 0.0384079 14.0474 0.0384079 13.6569 0.428932L8 6.08579L2.34315 0.428932C1.95262 0.0384079 1.31946 0.0384079 0.928932 0.428932C0.538408 0.819456 0.538408 1.45262 0.928932 1.84315L7.29289 8.20711ZM7 6.5V7.5H9V6.5H7Z"
              fill="#100F0F"
            />
          </svg>
        </div>
      ) : (
        text
      )}
    </button>
  ) : (
    <div onClick={onClick}>
      {active ? (
        <AiFillHeart className={styles.iconHeart} id={styles.heartActive} />
      ) : (
        <AiOutlineHeart className={styles.iconHeart} />
      )}
    </div>
  );
};

export default Button;
