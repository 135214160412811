import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import styles from "./ContactForm.module.scss";

const ContactForm = () => {
  const [state, handleSubmit] = useForm("xjvqjrzg");
  if (state.succeeded) {
    return (
      <p className={styles.contactSuccess}>
        Thanks for your message! 🥰 I'll get back to you as soon as possible 🦦
      </p>
    );
  }

  return (
    <form onSubmit={handleSubmit} className={styles.formContactContainer}>
      <div>
        <label htmlFor="name">
          Name <span>*</span>
        </label>
        <input id="name" type="text" name="name" required={true} />
        <ValidationError prefix="Name" field="name" errors={state.errors} />
      </div>
      <div>
        <label htmlFor="email">
          Email Address <span>*</span>
        </label>
        <input id="email" type="email" name="email" required={true} />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>
      <div className={styles.textareaContainer}>
        <label htmlFor="message">
          How can I help you? <span>*</span>
        </label>
        <textarea
          id="message"
          name="message"
          rows="5"
          cols="auto"
          required={true}
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
      </div>
      <button type="submit" disabled={state.submitting}>
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
