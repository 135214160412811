import { useState, useEffect } from "react";
import styles from "./ProjectImgGrid.module.scss";

const ProjectImgGrid = ({ img1, img2, alt1, alt2 }) => {
  const [imageFull1, setImageFull1] = useState(false);
  const [imageFull2, setImageFull2] = useState(false);
  const handleImageClick1 = () => {
    setImageFull1(!imageFull1);
  };

  const handleImageClick2 = () => {
    setImageFull2(!imageFull2);
  };
  const bodyStyle = imageFull1 || imageFull2 ? { overflow: "hidden" } : {};

  useEffect(() => {
    document.body.style.overflow = bodyStyle.overflow || "auto";
  }, [bodyStyle.overflow]);
  return (
    <div className={styles.imgContainer}>
      {/*IMG 1*/}
      {img1 && (
        <div
          className={styles.imgBox}
          id={styles.imgBox1}
          onClick={handleImageClick1}
        >
          <span className={styles.biggerImg}>Click me to see me bigger</span>
          {imageFull1 && (
            <div className={styles.imgFull} onClick={handleImageClick1}>
              <div className={styles.imgFullContent}>
                <figure>
                  <img src={img1} alt={alt1} />
                </figure>
              </div>
            </div>
          )}
          <img src={img1} alt={alt1} />
        </div>
      )}
      {/*IMG 2*/}
      {img2 && (
        <div
          className={styles.imgBox}
          id={styles.imgBox2}
          onClick={handleImageClick2}
        >
          <span className={styles.biggerImg}>Click me to see me bigger</span>
          {imageFull2 && (
            <div className={styles.imgFull} onClick={handleImageClick2}>
              <div className={styles.imgFullContent}>
                <figure>
                  <img src={img2} alt={alt2} />
                </figure>
              </div>
            </div>
          )}
          <img src={img2} alt={alt2} />
        </div>
      )}
    </div>
  );
};

export default ProjectImgGrid;
