import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import { Parallax } from "react-scroll-parallax";
import me1 from "../../../assets/me/sumol.png";
import me3 from "../../../assets/me/barbie.png";
import me4 from "../../../assets/me/edward.png";
import styles from "./ParallaxComp.module.scss";

const ParallaxComp = () => {
  return (
    <ParallaxProvider>
      <div className={styles.imgContainer}>
        <div id={styles.img1}>
          <Parallax translateY={[-30, 20]}>
            <img src={me1} alt="" />
          </Parallax>
        </div>

        <div id={styles.img4}>
          <Parallax translateY={[-60, 50]}>
            <img src={me3} alt="" />
          </Parallax>
        </div>

        <div id={styles.img3}>
          <Parallax translateY={[70, -10]}>
            <img src={me4} alt="" />
          </Parallax>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default ParallaxComp;
