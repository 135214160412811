import { useState, useRef, useEffect } from "react";
import ProjectsHero from "../../components/ProjectPage/ProjectsHero";
import Navbar from "../../components/General/Navbar";
import Footer from "../../components/General/Footer";
import Button from "../../components/General/Button";
import styles from "./Projects.module.scss";
import ProjectBody from "../../components/ProjectPage/ProjectBody";

const Projects = () => {
  const [more, setMore] = useState(false);
  const projectBodyContainerRef = useRef();

  const handleClickSeeMore = () => {
    setMore(!more);
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (more) {
      projectBodyContainerRef.current.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    }

    if (!more) {
      window.scrollTo(0, 0);
    }
  }, [more]);

  return (
    <section>
      <Navbar />
      <div className={styles.projectContent}>
        <a href="/work" onClick={handleClick}>
          &lt; {""} work
        </a>
        <ProjectsHero />
        <div ref={projectBodyContainerRef}>{more && <ProjectBody />}</div>
        <div className={styles.seeMoreButton}>
          <Button
            text={more ? "See less" : "Read more"}
            onClick={handleClickSeeMore}
          />
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Projects;
