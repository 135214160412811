import React from "react";
import styles from "./Contact.module.scss";
import Navbar from "../../components/General/Navbar";
import Footer from "../../components/General/Footer";
import ContactForm from "../../components/ContactPage/ContactForm";
import imgMe from "../../assets/me/patronum.png";

const Contact = () => {
  return (
    <section>
      <Navbar />
      <div className={styles.contactContainer}>
        <div className={styles.titleContact}>
          <h1 className={styles.hPC}>Let's talk! Don't be shy...</h1>
          <h1 className={styles.hMobile}>
            Let's talk! <br /> Don't be shy...
          </h1>
        </div>
        <div className={styles.ContactContent}>
          <ContactForm />
          <div className={styles.imgBox}>
            <img
              src={imgMe}
              alt="rapariga com uma fita no cabelo e gravata vermelha e amarela a fazer um feitiço, conhecido como patronum com tons azuis e um gato a sair do feitiço"
            />{" "}
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default Contact;
