import React, { useEffect } from "react";
import styles from "./About.module.scss";
import Button from "../../components/General/Button";
import Navbar from "../../components/General/Navbar";
import Footer from "../../components/General/Footer";
import TimeLine from "../../components/AboutPage/TimeLine";
import ParallaxComp from "../../components/AboutPage/ParallaxComp";
import Ticker from "../../components/General/Ticker";
import RotatingElement from "../../components/AboutPage/RotatingElement";
// import { ParallaxProvider } from "react-scroll-parallax";
// import { Parallax } from "react-scroll-parallax";
import star from "../../assets/svg/star.png";
import dot from "../../assets/svg/dot.svg";
import { useInView } from "react-intersection-observer";

const About = () => {
  const handleCVClick = () => {
    window.open(
      "https://drive.google.com/file/d/1jd04YbcQZjFXCWPhWuq0kfz-ld_uDXLJ/view?usp=drive_link",
      "_blank"
    );
  };

  const handlePortfolioClick = () => {
    window.open(
      "https://drive.google.com/file/d/1-Da2M1RJZ_A5Vm5FJCpdGRJPJmwjlmXv/view?usp=drive_link",
      "_blank"
    );
  };
  //
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const handleScroll = () => {
    if (inView) {
      const scrollYTrigger = window.scrollY;
      const offset = getOffset();
      const yPosition = -scrollYTrigger + offset;
      const element = document.querySelector(`.${styles.scrollText}`);
      if (element) {
        element.style.transform = ` translateX(${yPosition}px)`;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const getOffset = () => {
    const mq1 = window.matchMedia("(max-width: 900px)");
    const mq2 = window.matchMedia("(max-width: 750px)");
    const mq3 = window.matchMedia("(max-width: 500px)");
    const mq4 = window.matchMedia("(max-width: 430px)");
    const mq5 = window.matchMedia("(max-width: 415px)");
    const mq6 = window.matchMedia("(max-width: 400px)");
    const mq7 = window.matchMedia("(max-width: 390px)");
    const mq8 = window.matchMedia("(max-width: 375px)");

    if (mq8.matches) {
      return 960;
    } else if (mq7.matches) {
      return 920;
    } else if (mq6.matches) {
      return 945;
    } else if (mq5.matches) {
      return 870;
    } else if (mq4.matches) {
      return 820;
    } else if (mq3.matches) {
      return 680;
    } else if (mq2.matches) {
      return 1010;
    } else if (mq1.matches) {
      return 980;
    } else {
      return 750;
    }
  };

  return (
    <section>
      <Navbar />
      <Ticker
        big={true}
        speed={50}
        txt1="creative designer"
        txt2="cat lover"
        txt3="photographer as a hobby"
        txt4="knit to relieve stress"
        svg={star}
      />

      <div className={styles.aboutMe}>
        {/* <h1> About</h1> */}
        <div className={styles.aboutMeContainer}>
          {/*-------TOP Text-------*/}
          <div className={styles.aboutMeContent}>
            <p>
              <span>Hey there! I’m Francisca,</span> and it’s a pleasure to meet
              you! I am a 23 year old design and cat lover based in Coimbra,
              Portugal, who is passionate about creating visually stunning user
              interfaces.
              <br />
              <br />
              While in college, I stumbled upon the exciting world of product
              design. I'm all about crafting efficient and visually appealing
              user interfaces that are easy to use and can improve peoples’
              lives. Solving design challenges and coming up with creative and
              bold solutions to enhance user experience is what I am passionate
              about.
              <br />
              <br />
              Apart from product design, I am an enthusiastic web and graphic
              designer. I enjoy seeing my prototypes come to life on the web and
              experimenting with new graphic identities to create visually
              stunning designs.
              {/* <br />
              <br />
              Along the way, in 2019, I began working as a designer for
              jeKnowledge (a junior enterprise). Since then, I've had the
              opportunity to work on a variety of projects, speak with end
              users, improve my soft and hard skills, and grow as a designer. */}
              <br />
              <br />
              For the past year I worked in my dissertation project, where I
              redesigned a telerehabilitation application where I incorporated a
              gamification strategy. This provided me with invaluable experience
              in user research, heuristic evaluation, usability testing, user
              experience testing, designing both medium and high-fidelity
              prototypes and implement the application in React JS. It also
              allowed me to tailor my design skills to cater to aspecific
              audience.
              <br />
              <br />
              When I'm not immersed in the world of design, you can find me
              creating creative photography and video content for{" "}
              <a
                href="https://www.instagram.com/francisca_laureano_/"
                target="_blank"
                rel="noreferrer"
              >
                instagram
              </a>{" "}
              (give it a look 😉) !
            </p>
            <div className={styles.aboutButtons}>
              <Button
                text="My CV"
                anotherButton={true}
                onClick={handleCVClick}
              />
              <Button text="My pdf portfolio" onClick={handlePortfolioClick} />
            </div>
          </div>
          <ParallaxComp />
        </div>
        <div className={styles.software}>
          <div ref={ref} className={styles.scrollText}>
            <h2>"Queen of Photoshop"</h2>
          </div>
          <p>
            Through my mom eyes, at least... But moms know everything, am I
            right? I also master Figma, Adobe Illustrator, After Effects,
            InDesign, Miro and Final Cut Pro X.
            <br />
            <br />
            When it comes to programming languages I have worked with HTML, CSS,
            Sass, JavaScript, Typescript and React.
          </p>
        </div>
        <Ticker
          big={false}
          svg={dot}
          speed={35}
          txt1="ux/ui design"
          txt2="service design"
          txt3="web design"
          txt4="accessibility and universal design"
          txt5="graphic design"
          txt6="branding"
          txt7="photography"
        />
        <RotatingElement />
        <TimeLine />
      </div>
      <Footer />
    </section>
  );
};

export default About;
