import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./Work.module.scss";
import Navbar from "../../components/General/Navbar";
import Footer from "../../components/General/Footer";
import Button from "../../components/General/Button";
import WorkGrid from "../../components/WorkPage/WorkGrid";
import { workCoverData } from "../../Data/workCoverData";
//import { AiOutlinePlus } from "react-icons/ai";

const Work = () => {
  const [activeButton, setActiveButton] = useState("all");
  const [favoriteStates, setFavoriteStates] = useState({});
  const [filterOpen, setFilterOpen] = useState(false);

  const handleFilterOpen = () => {
    setFilterOpen(!filterOpen);
  };

  useEffect(() => {
    // Load favorite states from local storage on component mount
    const localStorageData = localStorage.getItem("favState");
    if (localStorageData) {
      try {
        setFavoriteStates(JSON.parse(localStorageData));
      } catch (error) {
        console.error("Error parsing JSON from Local Storage:", error);
      }
    }
  }, []);

  const toggleFavorite = (id) => {
    setFavoriteStates((prevStates) => {
      const updatedStates = { ...prevStates };
      updatedStates[id] = !updatedStates[id]; // Use o ID do projeto como chave

      localStorage.setItem("favState", JSON.stringify(updatedStates));

      return updatedStates;
    });
  };

  const handleButtonClick = (tag) => {
    setActiveButton(tag);
  };

  const filteredProjects = workCoverData.filter((project) => {
    if (activeButton === "all") {
      return true;
    } else if (activeButton === "fav") {
      return favoriteStates[project.id] === true;
    } else {
      return (
        project.tag1Txt === activeButton ||
        project.tag2Txt === activeButton ||
        project.tag3Txt === activeButton
      );
    }
  });

  return (
    <section className={styles.work}>
      <Navbar />
      <div className={styles.workContent}>
        <h1>Work</h1>
        <div className={styles.buttonFilter}>
          <Button
            text="all"
            active={activeButton === "all"}
            anotherButton={true}
            onClick={() => handleButtonClick("all")}
          />
          <Button
            text="web and app design"
            anotherButton={true}
            active={activeButton === "web/app design"}
            onClick={() => handleButtonClick("web/app design")}
          />
          <Button
            text="ux/ui design"
            anotherButton={true}
            active={activeButton === "ux/ui design"}
            onClick={() => handleButtonClick("ux/ui design")}
          />
          <Button
            text="graphic design"
            anotherButton={true}
            active={activeButton === "graphic design"}
            onClick={() => handleButtonClick("graphic design")}
          />
          <Button
            text="front-end"
            anotherButton={true}
            active={activeButton === "front-end"}
            onClick={() => handleButtonClick("front-end")}
          />
          {/* <Button
            text="branding"
            anotherButton={true}
            active={activeButton === "branding"}
            onClick={() => handleButtonClick("branding")}
          />
          <Button
            text="photography"
            active={activeButton === "photography"}
            onClick={() => handleButtonClick("photography")}
          /> */}
          <Button
            active={activeButton === "fav"}
            onClick={() => handleButtonClick("fav")}
          />
        </div>

        <div className={styles.buttonFilterMobile}>
          <Button
            text="all"
            active={activeButton === "all"}
            anotherButton={true}
            onClick={() => handleButtonClick("all")}
          />
          <Button
            text="ux/ui design"
            anotherButton={true}
            active={activeButton === "ux/ui design"}
            onClick={() => handleButtonClick("ux/ui design")}
          />
          <Button
            active={activeButton === "fav"}
            onClick={() => handleButtonClick("fav")}
          />

          {filterOpen && (
            <div className={styles.buttonFilterMobileOpen}>
              <Button
                text="web and app design"
                anotherButton={true}
                active={activeButton === "web/app design"}
                onClick={() => handleButtonClick("web/app design")}
              />

              {/* <Button
                text="branding"
                anotherButton={true}
                active={activeButton === "branding"}
                onClick={() => handleButtonClick("branding")}
              /> */}

              <Button
                text="graphic design"
                anotherButton={true}
                active={activeButton === "graphic design"}
                onClick={() => handleButtonClick("graphic design")}
              />

              <Button
                text="front-end"
                anotherButton={true}
                active={activeButton === "front-end"}
                onClick={() => handleButtonClick("front-end")}
              />

              {/* <Button
                text="photography"
                active={activeButton === "photography"}
                onClick={() => handleButtonClick("photography")}
              /> */}
            </div>
          )}

          {filterOpen ? (
            <button className={styles.buttonAdd} onClick={handleFilterOpen}>
              -
            </button>
          ) : (
            <button
              className={styles.buttonAdd}
              id={styles.buttonPlus}
              onClick={handleFilterOpen}
            >
              +
            </button>
          )}
        </div>
        <WorkGrid
          projects={filteredProjects}
          favoriteStates={favoriteStates}
          toggleFavorite={toggleFavorite}
        />
      </div>
      <Footer />
    </section>
  );
};

export default Work;
