// App.js

import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Work from "./pages/Work";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";

function App() {
  return (
    <section className="AppContainer">
      <div className="routesContainer">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/work" element={<Work />} />

          <Route path="/about" element={<About />} />
          <Route path="/work/:id" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </section>
  );
}

export default App;
