import React from 'react';
import Navbar from '../../components/General/Navbar'
import './Home.module.scss'
import Hero from '../../components/HomePage/Hero'
import About from '../../components/HomePage/About'
import Work from '../../components/HomePage/Work'
import Footer from '../../components/General/Footer'

const Home = () => {
  return (
    <section>
      <Navbar />
      <Hero href="#about"/>
      <About idAbout="about"/>
      <Work/>
      <Footer/>
    </section>
  )
}

export default Home