import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./ProjectBody.module.scss";
import { workBodyData } from "../../../Data/workBodyData";
import ProjectContent from "../ProjectContent";

const ProjectBody = () => {
  const { id } = useParams();

  // eslint-disable-next-line no-unused-vars
  const [cardContent, setCardContent] = useState(workBodyData[id]);
  const [activeNav, setActiveNav] = useState("topic1");

  const handleButtonClick = (tag) => {
    setActiveNav(tag);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: window.scrollY - 500,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: window.scrollY + 500,
      behavior: "smooth",
    });
  };

  const topics = Object.keys(cardContent).filter((key) =>
    key.startsWith("topic")
  );

  return (
    <section className={styles.projectBody}>
      <div className={styles.projectContent}>
        <ProjectContent
          img={cardContent.img1}
          img0={cardContent.img11}
          img1={cardContent.img111}
          img11={cardContent.img112}
          img2={cardContent.img121}
          img21={cardContent.img122}
          img3={cardContent.img131}
          img31={cardContent.img132}
          img4={cardContent.img141}
          img41={cardContent.img142}
          img5={cardContent.img151}
          img51={cardContent.img152}
          idTopic="topic1"
          topic={cardContent.topic1}
          p={cardContent.txt1}
          isSub1={cardContent.isSub11}
          idSubTopic1="topic11"
          subTopic1={cardContent.subTopic11}
          p1={cardContent.txt11}
          isSub2={cardContent.isSub12}
          idSubTopic2="topic12"
          subTopic2={cardContent.subTopic12}
          p2={cardContent.txt12}
          isSub3={cardContent.isSub13}
          idSubTopic3="topic13"
          subTopic3={cardContent.subTopic13}
          p3={cardContent.txt13}
          isSub4={cardContent.isSub14}
          idSubTopic4="topic14"
          subTopic4={cardContent.subTopic14}
          p4={cardContent.txt14}
          isSub5={cardContent.isSub15}
          idSubTopic5="topic15"
          subTopic5={cardContent.subTopic15}
          p5={cardContent.txt15}
        />
        <ProjectContent
          img={cardContent.img2}
          img0={cardContent.img21}
          img1={cardContent.img211}
          img11={cardContent.img212}
          img2={cardContent.img221}
          img21={cardContent.img222}
          img3={cardContent.img231}
          img31={cardContent.img232}
          img4={cardContent.img241}
          img41={cardContent.img242}
          img5={cardContent.img251}
          img51={cardContent.img252}
          idTopic="topic2"
          topic={cardContent.topic2}
          p={cardContent.txt2}
          isSub1={cardContent.isSub21}
          idSubTopic1="topic21"
          subTopic1={cardContent.subTopic21}
          p1={cardContent.txt21}
          isSub2={cardContent.isSub22}
          idSubTopic2="topic22"
          subTopic2={cardContent.subTopic22}
          p2={cardContent.txt22}
          isSub3={cardContent.isSub23}
          idSubTopic3="topic23"
          subTopic3={cardContent.subTopic23}
          p3={cardContent.txt23}
          isSub4={cardContent.isSub24}
          idSubTopic4="topic24"
          subTopic4={cardContent.subTopic24}
          p4={cardContent.txt24}
          isSub5={cardContent.isSub25}
          idSubTopic5="topic25"
          subTopic5={cardContent.subTopic25}
          p5={cardContent.txt25}
        />

        <ProjectContent
          img={cardContent.img3}
          img0={cardContent.img31}
          img1={cardContent.img311}
          img11={cardContent.img312}
          img2={cardContent.img321}
          img21={cardContent.img322}
          img3={cardContent.img331}
          img31={cardContent.img332}
          img4={cardContent.img341}
          img41={cardContent.img342}
          img5={cardContent.img351}
          img51={cardContent.img352}
          idTopic="topic3"
          topic={cardContent.topic3}
          p={cardContent.txt3}
          isSub1={cardContent.isSub31}
          idSubTopic1="topic31"
          subTopic1={cardContent.subTopic31}
          p1={cardContent.txt31}
          isSub2={cardContent.isSub32}
          idSubTopic2="topic32"
          subTopic2={cardContent.subTopic32}
          p2={cardContent.txt32}
          isSub3={cardContent.isSub33}
          idSubTopic3="topic33"
          subTopic3={cardContent.subTopic33}
          p3={cardContent.txt33}
          isSub4={cardContent.isSub34}
          idSubTopic4="topic34"
          subTopic4={cardContent.subTopic34}
          p4={cardContent.txt34}
          isSub5={cardContent.isSub35}
          idSubTopic5="topic35"
          subTopic5={cardContent.subTopic35}
          p5={cardContent.txt35}
        />

        <ProjectContent
          img={cardContent.img4}
          img0={cardContent.img41}
          img1={cardContent.img411}
          img11={cardContent.img412}
          img2={cardContent.img421}
          img21={cardContent.img422}
          img3={cardContent.img431}
          img31={cardContent.img432}
          img4={cardContent.img441}
          img41={cardContent.img442}
          img5={cardContent.img451}
          img51={cardContent.img452}
          idTopic="topic4"
          topic={cardContent.topic4}
          p={cardContent.txt4}
          isSub1={cardContent.isSub41}
          idSubTopic1="topic41"
          subTopic1={cardContent.subTopic41}
          p1={cardContent.txt41}
          isSub2={cardContent.isSub42}
          idSubTopic2="topic42"
          subTopic2={cardContent.subTopic42}
          p2={cardContent.txt42}
          isSub3={cardContent.isSub43}
          idSubTopic3="topic43"
          subTopic3={cardContent.subTopic43}
          p3={cardContent.txt43}
          isSub4={cardContent.isSub44}
          idSubTopic4="topic44"
          subTopic4={cardContent.subTopic44}
          p4={cardContent.txt44}
          isSub5={cardContent.isSub45}
          idSubTopic5="topic45"
          subTopic5={cardContent.subTopic45}
          p5={cardContent.txt45}
        />

        <ProjectContent
          img={cardContent.img5}
          img0={cardContent.img51}
          img1={cardContent.img511}
          img11={cardContent.img512}
          img2={cardContent.img521}
          img21={cardContent.img522}
          img3={cardContent.img531}
          img31={cardContent.img532}
          img4={cardContent.img541}
          img41={cardContent.img542}
          img5={cardContent.img551}
          img51={cardContent.img552}
          idTopic="topic5"
          topic={cardContent.topic5}
          p={cardContent.txt5}
          isSub1={cardContent.isSub51}
          idSubTopic1="topic51"
          subTopic1={cardContent.subTopic51}
          p1={cardContent.txt51}
          isSub2={cardContent.isSub52}
          idSubTopic2="topic52"
          subTopic2={cardContent.subTopic52}
          p2={cardContent.txt52}
          isSub3={cardContent.isSub53}
          idSubTopic3="topic53"
          subTopic3={cardContent.subTopic53}
          p3={cardContent.txt53}
          isSub4={cardContent.isSub54}
          idSubTopic4="topic54"
          subTopic4={cardContent.subTopic54}
          p4={cardContent.txt54}
          isSub5={cardContent.isSub55}
          idSubTopic5="topic55"
          subTopic5={cardContent.subTopic55}
          p5={cardContent.txt55}
        />

        <ProjectContent
          img={cardContent.img6}
          img0={cardContent.img61}
          img1={cardContent.img611}
          img11={cardContent.img612}
          img2={cardContent.img621}
          img21={cardContent.img622}
          img3={cardContent.img631}
          img31={cardContent.img632}
          img4={cardContent.img641}
          img41={cardContent.img642}
          img5={cardContent.img651}
          img51={cardContent.img652}
          idTopic="topic6"
          topic={cardContent.topic6}
          p={cardContent.txt6}
          isSub1={cardContent.isSub61}
          idSubTopic1="topic61"
          subTopic1={cardContent.subTopic61}
          p1={cardContent.txt61}
          isSub2={cardContent.isSub62}
          idSubTopic2="topic62"
          subTopic2={cardContent.subTopic62}
          p2={cardContent.txt62}
          isSub3={cardContent.isSub63}
          idSubTopic3="topic63"
          subTopic3={cardContent.subTopic63}
          p3={cardContent.txt63}
          isSub4={cardContent.isSub64}
          idSubTopic4="topic64"
          subTopic4={cardContent.subTopic64}
          p4={cardContent.txt64}
          isSub5={cardContent.isSub65}
          idSubTopic5="topic65"
          subTopic5={cardContent.subTopic65}
          p5={cardContent.txt65}
        />

        <ProjectContent
          img={cardContent.img7}
          img0={cardContent.img71}
          img1={cardContent.img711}
          img11={cardContent.img712}
          img2={cardContent.img721}
          img21={cardContent.img722}
          img3={cardContent.img731}
          img31={cardContent.img732}
          img4={cardContent.img741}
          img41={cardContent.img742}
          img5={cardContent.img751}
          img51={cardContent.img752}
          idTopic="topic7"
          topic={cardContent.topic7}
          p={cardContent.txt7}
          isSub1={cardContent.isSub71}
          idSubTopic1="topic71"
          subTopic1={cardContent.subTopic71}
          p1={cardContent.txt71}
          isSub2={cardContent.isSub72}
          idSubTopic2="topic72"
          subTopic2={cardContent.subTopic72}
          p2={cardContent.txt72}
          isSub3={cardContent.isSub73}
          idSubTopic3="topic73"
          subTopic3={cardContent.subTopic73}
          p3={cardContent.txt73}
          isSub4={cardContent.isSub74}
          idSubTopic4="topic74"
          subTopic4={cardContent.subTopic74}
          p4={cardContent.txt74}
          isSub5={cardContent.isSub75}
          idSubTopic5="topic75"
          subTopic5={cardContent.subTopic75}
          p5={cardContent.txt75}
        />

        <ProjectContent
          img={cardContent.img8}
          img0={cardContent.img81}
          img1={cardContent.img811}
          img11={cardContent.img812}
          img2={cardContent.img821}
          img21={cardContent.img822}
          img3={cardContent.img831}
          img31={cardContent.img832}
          img4={cardContent.img841}
          img41={cardContent.img842}
          img5={cardContent.img851}
          img51={cardContent.img852}
          idTopic="topic8"
          topic={cardContent.topic8}
          p={cardContent.txt8}
          isSub1={cardContent.isSub81}
          idSubTopic1="topic81"
          subTopic1={cardContent.subTopic81}
          p1={cardContent.txt81}
          isSub2={cardContent.isSub82}
          idSubTopic2="topic82"
          subTopic2={cardContent.subTopic82}
          p2={cardContent.txt82}
          isSub3={cardContent.isSub83}
          idSubTopic3="topic83"
          subTopic3={cardContent.subTopic83}
          p3={cardContent.txt83}
          isSub4={cardContent.isSub84}
          idSubTopic4="topic84"
          subTopic4={cardContent.subTopic84}
          p4={cardContent.txt84}
          isSub5={cardContent.isSub85}
          idSubTopic5="topic85"
          subTopic5={cardContent.subTopic85}
          p5={cardContent.txt85}
        />
      </div>
      {/*-----------PROJECT NAVIGATION----------- */}
      <div className={styles.projectNavigation}>
        <div className={styles.navContainer}>
          {/* Render navigation links based on available topics */}
          {topics.map((topic, index) => (
            <a
              key={index}
              className={activeNav === topic && styles.activeNav}
              onClick={() => handleButtonClick(topic)}
              href={`#${topic}`}
            >
              {cardContent[topic]}
            </a>
          ))}
        </div>
      </div>

      <div className={styles.projectMobileNav}>
        <div id={styles.upArrow} onClick={scrollToTop}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="39"
            viewBox="0 0 16 39"
            fill="none"
          >
            <path
              d="M7.29289 0.292892C7.68342 -0.0976295 8.31658 -0.0976296 8.70711 0.292892L15.0711 6.65685C15.4616 7.04738 15.4616 7.68054 15.0711 8.07107C14.6805 8.46159 14.0474 8.46159 13.6569 8.07107L8 2.41422L2.34314 8.07107C1.95262 8.46159 1.31946 8.46159 0.928931 8.07107C0.538406 7.68054 0.538406 7.04738 0.928931 6.65685L7.29289 0.292892ZM7 39L7 1L9 1L9 39L7 39Z"
              fill="#FFF7E1"
            />
          </svg>
        </div>
        <div id={styles.downArrow} onClick={scrollToBottom}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="39"
            viewBox="0 0 16 39"
            fill="none"
          >
            <path
              d="M7.29289 38.7071C7.68342 39.0976 8.31658 39.0976 8.70711 38.7071L15.0711 32.3431C15.4616 31.9526 15.4616 31.3195 15.0711 30.9289C14.6805 30.5384 14.0474 30.5384 13.6569 30.9289L8 36.5858L2.34314 30.9289C1.95262 30.5384 1.31946 30.5384 0.928931 30.9289C0.538406 31.3195 0.538406 31.9526 0.928931 32.3431L7.29289 38.7071ZM7 -4.37114e-08L7 38L9 38L9 4.37114e-08L7 -4.37114e-08Z"
              fill="#FFF7E1"
            />
          </svg>
        </div>
      </div>
    </section>
  );
};

export default ProjectBody;
