import React, { useState, useEffect } from "react";
import styles from "./Globe.module.scss";

const Globe = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const calculateRotation = () => {
    const { x, y } = cursorPosition;
    const rotationX = (y / window.innerHeight - 0.5) * 60;
    const rotationY = (x / window.innerWidth - 0.5) * 60;

    return `rotateX(${rotationX}deg) rotateY(${rotationY}deg)`;
  };

  const calculateRotation2 = () => {
    const { x, y } = cursorPosition;
    const rotationX = (y / window.innerHeight - 0.7) * -30;
    const rotationY = (x / window.innerWidth - 0.7) * -30;
    const initialRotation = -90;

    return `rotateX(${rotationX}deg) rotateY(${rotationY}deg) rotate(${initialRotation}deg)`;
  };

  const calculateRotation3 = () => {
    const { x, y } = cursorPosition;
    const rotationX = (y / window.innerHeight - 0.8) * 45;
    const rotationY = (x / window.innerWidth - 0.8) * -45;
    const initialRotation = -90;

    return `rotateX(${rotationX}deg) rotateY(${rotationY}deg) rotate(${initialRotation}deg)`;
  };

  const calculateRotation4 = () => {
    const { x, y } = cursorPosition;
    const rotationX = (y / window.innerHeight - 0.8) * -25;
    const rotationY = (x / window.innerWidth - 0.8) * 55;
    const initialRotation = -90;

    return `rotateX(${rotationX}deg) rotateY(${rotationY}deg) rotate(${initialRotation}deg)`;
  };

  const calculateRotation5 = () => {
    const { x, y } = cursorPosition;
    const rotationX = (y / window.innerHeight - 0.8) * -75;
    const rotationY = (x / window.innerWidth - 0.8) * -55;
    const initialRotation = -90;

    return `rotateX(${rotationX}deg) rotateY(${rotationY}deg) rotate(${initialRotation}deg)`;
  };

  const calculateRotation6 = () => {
    const { x, y } = cursorPosition;
    const rotationX = (y / window.innerHeight - 0.8) * -5;
    const rotationY = (x / window.innerWidth - 0.8) * 55;

    return `rotateX(${rotationX}deg) rotateY(${rotationY}deg) `;
  };

  const calculateRotation7 = () => {
    const { x, y } = cursorPosition;
    const rotationX = (y / window.innerHeight - 0.8) * -95;
    const rotationY = (x / window.innerWidth - 0.8) * -45;

    return `rotateX(${rotationX}deg) rotateY(${rotationY}deg) `;
  };
  return (
    <div className={styles.heartContainer}>
      <div className={styles.heart}></div>
      <div
        className={styles.heart2}
        style={{ transform: calculateRotation() }}
      ></div>
      <div
        className={styles.heart3}
        style={{ transform: calculateRotation2() }}
      ></div>
      <div
        className={styles.heart4}
        style={{ transform: calculateRotation3() }}
      ></div>
      <div
        className={styles.heart5}
        style={{ transform: calculateRotation4() }}
      ></div>
      <div
        className={styles.heart6}
        style={{ transform: calculateRotation5() }}
      ></div>
      <div
        className={styles.heart7}
        style={{ transform: calculateRotation6() }}
      ></div>
      <div
        className={styles.heart8}
        style={{ transform: calculateRotation7() }}
      ></div>
    </div>
  );
};

export default Globe;
