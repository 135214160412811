import React from "react";
import styles from "./TimeSection.module.scss";

const TimeSection = ({ text, time, location, id }) => {
  return (
    <div className={`${styles.timeSection}  ${location}`} id={id}>
      <span>{text}</span>
      <div className={`${styles.underlineContainer} ${styles[time]}`}>
        <div className={styles.square} />
        <div className={styles.line} />
        <div className={styles.circle} />
      </div>
    </div>
  );
};

export default TimeSection;
